.react-datepicker-popper {
  z-index: 500 !important;
}

.form-input-control {
  display: flex;
  height: 48px;
  /* padding: 12px 16px; */
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 0.5px solid var(--Secondary, #999);
  width: 100%;
}
.css-1etl5ke-control {
  height: 48px;
}
.css-6yuvde-control {
  height: 48px;
}
.placeholder-input {
  color: var(--Secondary, #999);
  font-feature-settings: "clig" off, "liga" off;

  /* Regular/Small */
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
}
