.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: "Nunito Sans", sans-serif; /* Add this to enable smooth scrolling */
  overflow: visible;
}

.text-outline {
  font-size: 48px;
  color: #000; /* Set the primary text color */
  text-shadow: -1px -1px 0 #fff, /* Top-left */ 1px -1px 0 #fff,
    /* Top-right */ -1px 1px 0 #fff, /* Bottom-left */ 1px 1px 0 #fff; /* Bottom-right */
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust the max-width as needed */
}

@media (min-width: 768px) {
  .paddingComponentRight {
    padding-right: 52px;
  }
  .paddingComponentLeft {
    padding-left: 52px;
  }

  .text-title {
    color: var(--White, #fff);

    /* Bold/Heading 2 */
    font-family: Nunito Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .text-title-card {
    font-size: 24px;
  }
  .text-desc-title {
    color: #999;

    /* Regular/Medium */
    display: flex;
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .text-banner {
    color: var(--White, #fff);

    /* Bold/Heading 2 */
    font-family: Nunito Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .text-desc-banner {
    overflow: hidden;
    color: var(--White, #fff);
    text-overflow: ellipsis;

    /* Regular/Medium */
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .width-why-us {
    width: 60%;
  }
  .width-location {
    width: 40%;
  }

  .hero-background {
    background-image: url("./assets/HeroImage.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right bottom;
    height: fit-content;
  }
  .hero-title {
    font-size: 72px;
  }
  .text-title-green {
    font-size: 18px;
    color: #53f60f;
  }
  .text-title-hero {
    font-family: Nunito Sans;
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 75px; /* 104.167% */
    letter-spacing: 0.3px;
  }
  .title-hero-box {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: left;
    align-items: flex-start;
    text-align: left;
  }
  .btnRegister {
    display: flex;
    width: 141px;
    height: 48px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }
  .btnContact {
    display: flex;
    width: 141px;
    height: 48px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 5px;
  }
  .box-image-location {
    width: 100%;
    min-width: 474px;
    height: 459px;
    flex-shrink: 0;
    border-top-right-radius: 135px;
  }
  .box-radius-image-location {
    border-top-right-radius: 135px;
  }
  .box-desc-title {
    display: flex;
    width: 50%;
  }
  .box-facilities-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    /* width: 100%; */
  }
  .box-facilities-card-categories {
    display: flex;
    flex-direction: column;
    width: 278px;
    height: 288px;
    flex-shrink: 0;
    background-color: #18181c;
    border-radius: 10px;
    /* justify-content: space-between; */
    align-items: center;
    padding: 24px;
    /* gap: 16px; */
  }
  .text-desc-title-categories {
    display: flex;
    color: var(--Secondary, #999);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;

    /* Regular/Small */
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .text-title-pricing {
    color: var(--White, #fff);

    /* Bold/XXL */
    font-family: Nunito Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .box-pricing {
    display: flex;
    flex-direction: column;
    width: 278px;
    height: 420px;
    flex-shrink: 0;
    background-color: #18181c;
    border: 1px solid #999;
    border-radius: 10px;
    justify-content: space-between;
    padding: 24px;
  }
  .text-daftar-member {
    text-align: center;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
  .text-recomenation {
    color: var(--Black, #030304);
    font-feature-settings: "clig" off, "liga" off;

    /* Bold/Small */
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .box-title-banner {
    padding: 32px 32px 32px 56px;
  }
  .dot {
    width: 200px; /* Adjust the size of the dot */
    height: 200px;
    background-color: #508f35; /* Color of the dot */
    border-radius: 50%; /* Makes the element a circle */
    position: absolute;
  }

  #dot1 {
    top: 430px; /* Adjust the vertical position of the first dot */
    left: -100px; /* Adjust the horizontal position of the first dot */
    filter: blur(150px);
  }

  #dot2 {
    top: 1200px; /* Adjust the vertical position of the second dot */
    right: -100px; /* Adjust the horizontal position of the second dot */
    filter: blur(150px);
  }

  #dot3 {
    top: 2700px; /* Adjust the vertical position of the third dot */
    left: -70px; /* Adjust the horizontal position of the third dot */
    filter: blur(125px);
  }
}

@media (max-width: 768px) {
  body {
    font-size: 14px; /* Decrease font size for smaller screens */
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .paddingComponentRight {
    padding-right: 0px;
  }
  .paddingComponentLeft {
    padding-left: 0px;
  }
  .container {
    /* width: 90%; */
    margin: 0 auto; /* Center the container */
  }
  .menu {
    display: none; /* Hide the menu on smaller screens */
  }

  .mobile-menu {
    display: block; /* Show a mobile-friendly menu on smaller screens */
  }

  .card {
    width: 100%; /* Make cards fill the width of the container on smaller screens */
    margin-bottom: 20px; /* Add spacing between cards */
  }

  .text-title {
    color: var(--White, #fff);

    /* Bold/XXL */
    font-family: Nunito Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .text-title-card {
    font-size: 18px;
  }
  .text-desc-title {
    color: #999;

    /* Regular/Medium */
    display: flex;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .text-banner {
    color: var(--White, #fff);
    text-align: center;

    /* Bold/XXL */
    font-family: Nunito Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .text-desc-banner {
    color: var(--White, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;

    /* Regular/Small */
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .width-why-us {
    width: 100%;
  }
  .width-location {
    width: 100%;
  }
  .hero-title {
    font-size: 56px;
  }
  .text-title-green {
    font-size: 12px;
    color: #53f60f;
  }
  .text-title-hero {
    font-family: Nunito Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .title-hero-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .btnRegister {
    display: flex;
    width: 162px;
    height: 48px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }
  .btnContact {
    display: flex;
    width: 162px;
    height: 48px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 5px;
  }
  .box-image-location {
    width: 100%;
    min-width: 343px;
    height: 332px;
    flex-shrink: 0;
    border-top-right-radius: 135px;
  }

  .box-radius-image-location {
    border-top-right-radius: 150px;
  }
  .box-desc-title {
    display: flex;
    width: 100%;
  }
  .box-facilities-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: scroll;
    gap: 10px;
    width: 100%;
  }
  .box-facilities-card-categories {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 288px;
    flex-shrink: 0;
    background-color: #18181c;
    border-radius: 10px;
    /* justify-content: space-between; */
    align-items: center;
    padding: 32px;
    gap: 16px;
  }
  .text-desc-title-categories {
    color: var(--Secondary, #999);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;

    /* Regular/Small */
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .text-title-pricing {
    color: var(--White, #fff);

    /* Bold/Medium */
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .box-pricing {
    display: flex;
    flex-direction: column;
    width: 163px;
    height: 300px;
    flex-shrink: 0;
    background-color: #18181c;
    border: 1px solid #999;
    border-radius: 10px;
    justify-content: space-between;
    padding: 16px;
  }
  .text-daftar-member {
    text-align: center;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
  .text-recomenation {
    color: var(--Black, #030304);
    font-feature-settings: "clig" off, "liga" off;

    /* Bold/Small */
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  .box-title-banner {
    padding: 32px;
  }
  .dot {
    width: 151px; /* Adjust the size of the dot */
    height: 151px;
    background-color: #508f35; /* Color of the dot */
    border-radius: 50%; /* Makes the element a circle */
    position: absolute;
    filter: blur(100px);
  }

  #dot1 {
    top: 600px; /* Adjust the vertical position of the first dot */
    left: -70px; /* Adjust the horizontal position of the first dot */
  }

  #dot2 {
    top: 1600px; /* Adjust the vertical position of the second dot */
    right: -70px; /* Adjust the horizontal position of the second dot */
  }

  #dot3 {
    top: 3000px; /* Adjust the vertical position of the third dot */
    left: -70px; /* Adjust the horizontal position of the third dot */
  }

  .navbar {
    position: fixed;
    width: 100%;
    z-index: 99999;
    top: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black; /* Semi-transparent black overlay */
  z-index: 9999;
}

.overlay.active {
  display: block; /* Show the overlay when it has the 'active' class */
}

.max-w-screen-sm {
  max-width: 375px !important;
  padding: auto;
}
.text-primary {
  color: #53f60f;
}
.text-secondary {
  color: #999999;
}
.text-danger {
  color: #f15c59;
}
.otpContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.otpContainer input {
  display: flex;
  color: var(--White, #fff);
  text-align: center;

  /* Bold/Extra Large */
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
  min-width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--Secondary, #999);
  background-color: transparent;
}
.imageScroll {
  scroll-snap-align: center;
}
.scroll-animation {
  animation: scroll 5s linear infinite; /* Apply the scroll animation to the specific image */
}
@keyframes scroll {
  0% {
    transform: translateX(
      100%
    ); /* Start scrolling from the right edge of the image */
  }
  100% {
    transform: translateX(-100%); /* Scroll to the left edge of the image */
  }
}
.container-image-scroll {
  overflow-x: scroll;
  /* white-space: nowrap; */
  scroll-snap-type: x mandatory;
}

/* @media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: max-content;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
} */

.container-fluid {
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  min-width: auto;
}

/* width */
div::-webkit-scrollbar {
  height: 5px;
  width: 0px;
}

/* Track */
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px black;
  /* border-radius: 50%; */
  /* border-width: 2px; */
}

/* Handle */
div::-webkit-scrollbar-thumb {
  background: #53f60f;
  /* border-radius: 50%; */
  scrollbar-width: auto;
  border-radius: 100px;
  border-width: thin;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
  background: #53f60f;
}

.active-menu-item {
  color: #53f60f; /* Change this to your desired text color */
}

.scrolling-container {
  width: 100%;
  position: relative;
  white-space: nowrap;
  animation: scroll 20s linear infinite; /* Adjust animation duration as needed */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

.label-text-input {
  color: #fff;
  font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}
.upload-file-container {
  display: flex;
  width: 191px;
  height: 93px;
  /* padding: 12px 16px; */
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px dashed var(--normal-n-300, #c0c3cf);
  cursor: pointer;
}
.display-file-container {
  display: flex;
  width: 191px;
  height: 93px;
  /* padding: 12px 16px; */
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px dashed var(--normal-n-300, #c0c3cf);
}
