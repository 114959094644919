.form-input-control {
  display: flex;
  height: 48px;
  /* padding: 12px 16px; */
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 0.5px solid var(--Secondary, #999);
  width: 100%;
  padding-left: 8px;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
